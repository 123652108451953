import {
  ref, watch, computed,
} from '@vue/composition-api'
import Vue from 'vue'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import loadingRegister from '@/libs/loading/loading'
import store from '@/store'
import {
  trim,
  // , setDatetimeDefault, handlerTimestamp
} from '@/libs/utils'

Vue.use(loadingRegister)

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()
  // 筛选项
  const userStatus = ref({ label: 'All', value: 0 })
  const country = ref({ name: 'All', code: '' })
  const timeRange = ref([])
  const timeRangeDefault = ref([])
  // *取消默认时间*
  // timeRangeDefault.value = setDatetimeDefault(1, 1)
  // timeRange.value = handlerTimestamp(
  //   timeRangeDefault.value[0],
  //   timeRangeDefault.value[1],
  //   'America/Sao_Paulo',
  // )
  const mode = ref({ label: 'User ID', value: 3 })
  const searchKey = ref(null)
  // 下拉选项
  const modeOptions = ref([])
  // 表格操作
  const refUserCenterListTable = ref(null)
  const tableColumns = [
    { key: 'uuid', label: 'User ID' },
    { key: 'name', label: 'Name' },
    { key: 'email', label: 'Email' },
    { key: 'isoCode', label: 'Country' },
    { key: 'cep', label: 'Post Code' },
    { key: 'createdAt', label: 'Create time' },
    { key: 'documentId', label: 'Document ID' },
    { key: 'userName', label: 'Phone number' },
    { key: 'source', label: 'source' },
    { key: 'kycStatus', label: 'KYC status' },
    { key: '', label: 'Email' },
    { key: 'userStatus', label: 'Status' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const userCenterList = ref([])
  const dataMeta = computed(() => {
    const localItemsCount = refUserCenterListTable.value ? refUserCenterListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => { refUserCenterListTable.value.refresh() }
  watch([currentPage, perPage], () => { refetchData() })

  const fetchUserList = () => {
    Vue.prototype.$showLoading()
    const params = {
      model: Number(mode.value.value),
      userStatus: Number(userStatus.value.value),
      startDate: '',
      endDate: '',
      pageNo: currentPage.value,
      pageSize: perPage.value,
      isoCode: country.value.code,
    }
    if (params.model == 7) { // name
      params.searchKey = searchKey.value
    } else {
      params.searchKey = trim(searchKey.value)
    }
    if ((params.model == 4) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\d]/g, '') // 过滤后剩下数字
    }
    if ((params.model == 5) && params.searchKey) { // 4为phone 5为documentid
      params.searchKey = params.searchKey.replace(/[^\dA-Za-z]/g, '') // 过滤后剩下数字字母
    }
    if (timeRange.value.length > 0) {
      [params.startDate, params.endDate] = timeRange.value
    } else {
      params.startDate = null
      params.endDate = null
    }
    store.dispatch('userCenter/fetchUserList', params).then(response => {
      Vue.prototype.$hideLoading()
      if (response.data.code == 200 && response.data.data) {
        const { list, page } = response.data.data
        userCenterList.value = list
        totalInvoices.value = page.tatalCount
      } else if (response.data.code == 200 && !response.data.data) {
        userCenterList.value = []
        totalInvoices.value = 0
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }).catch(error => {
      Vue.prototype.$hideLoading()
      toast({
        component: ToastificationContent,
        props: {
          title: error.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    refUserCenterListTable,

    modeOptions,

    searchKey,
    mode,
    userStatus,
    timeRange,
    timeRangeDefault,
    country,

    userCenterList,
    fetchUserList,
  }
}

// const source = ref({ source: 'All', value: '-1' })
// const sourceOptions = ref([])
// const fetchSourceList = () => {
//   store.dispatch('userCenter/fetchSourceList').then(response => {
//     if (response.data.code == 200) {
//       sourceOptions.value = response.data.data.map(n => ({
//         source: n.source,
//         value: n.source,
//       }))
//       const defaultValue = [{ source: 'All', value: '-1' }]
//       sourceOptions.value = defaultValue.concat(sourceOptions.value)
//       store.state.userCenter.sourceOptions = sourceOptions.value
//     } else {
//       toast({
//         component: ToastificationContent,
//         props: {
//           title: response.data.message,
//           icon: 'AlertTriangleIcon',
//           variant: 'danger',
//         },
//       })
//     }
//   }).catch(error => {
//     toast({
//       component: ToastificationContent,
//       props: {
//         title: error.message,
//         icon: 'AlertTriangleIcon',
//         variant: 'danger',
//       },
//     })
//   })
// }
