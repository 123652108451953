<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Status:</label>
            <v-select
              v-model="userStatus"
              :options="statusOption"
              class="invoice-filter-select mb-1 no-clear-select"
              placeholder="All"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Country:</label>
            <v-select
              v-model="country"
              :options="countryOptions"
              class="invoice-filter-select mb-1 no-clear-select"
              placeholder="country"
              label="name"
            />
          </b-col>
          <b-col md="4">
            <label
              class="mr-sm-1"
              for="inline-form-custom-select-pref"
            >Time:</label>
            <date-picker
              :time-range-default="timeRangeDefault"
              @TimeRange="getTimeRange"
            />
          </b-col>

          <!-- <b-col md="4">
            <label for="inline-form-custom-select-pref">Mode:</label>
            <v-select
              v-model="mode"
              :options="modeOptions"
              class="invoice-filter-select mb-1 no-clear-select"
              placeholder="Wallet ID"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <label for="inline-form-custom-select-pref">Search Key:</label>
            <b-form-input
              v-model="searchKey"
              class="d-inline-block mb-1"
              placeholder="Search..."
              autocomplete="off"
            />
          </b-col> -->
        </b-row>
        <!-- <b-row>
          <b-col md="4">
            <label for="inline-form-custom-select-pref">Source:</label>
            <v-select
              v-model="source"
              :options="sourceOptions"
              class="invoice-filter-select mb-1 no-clear-select"
              placeholder="source"
              label="source"
            />
          </b-col>
        </b-row> -->
        <b-row>
          <b-col md="6">
            <label for="inline-form-custom-select-pref">Search Terms:</label>
            <b-input-group>
              <b-input-group-prepend>
                <b-dropdown
                  :text="mode.label"
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="(item, index) in modeOptions"
                    :key="index"
                    @click="modeOptionChange(item)"
                  >
                    {{ item.label }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group-prepend>
              <b-form-input
                v-model="searchKey"
                placeholder="Search..."
                autocomplete="off"
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="primary"
              class="mr-2 b-col-top"
              @click="search"
            >Search</b-button>
            <b-button
              variant="success"
              class="mr-2 b-col-top"
              @click="reset"
            >Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-table
      id="userCenterListTable"
      ref="refUserCenterListTable"
      :items="userCenterList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Column: uuid -->
      <template #cell(uuid)="data">
        <b-link
          :to="{
            path: '/usercenter/user-detail',
            query: { id: data.item.uuid, timezone: timeZone },
          }"
          class="font-weight-bold"
          target="_blank"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Invoice userStatus -->
      <template #cell(userStatus)="data">
        {{ showStatus[data.value] }}
      </template>

      <!-- Column: Issued createdAt -->
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ updateDate(data.value, timeZone) }}
        </span>
      </template>

      <!-- Column: Issued documentId -->
      <template #cell(documentId)="data">
        <span class="text-nowrap">
          {{ formatDocumentId(data.value, data.item.currency) }}
        </span>
      </template>

      <!-- Column: Issued userName -->
      <template #cell(userName)="data">
        <span class="text-nowrap">
          {{ formatPhoneNumber(data.value) }}
        </span>
      </template>

      <!-- Column: Issued kycStatus -->
      <template #cell(kycStatus)="data">
        <span class="text-nowrap">
          {{ formatKycStatustext(data.value) }}
        </span>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span>
          <!-- <span>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="mx-1 sct-self"
            />
          </span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePagination"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormSelect,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'
import {
  updateDate,
  formatDocumentId,
  formatPhoneNumber,
} from '@/libs/utils'
import store from '@/store'
import DatePicker from '@/component/Datepicker.vue'
import useDeposit from './useUserCenter'
import userCenterStoreModule from './userCenterStoreModule'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')

export default {
  components: {
    BForm,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    DatePicker,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateWallet(['timeZone', 'countrys']),
    countryOptions() {
      return [{ name: 'All', code: '' }].concat(this.countrys)
    },
  },
  watch: {
    timeZone() {
      // this.timeRange = []
      // // this.timeRange = handlerTimestamp(
      // //   this.timeRangeDefault[0],
      // //   this.timeRangeDefault[1],
      // //   value,
      // // )
      // this.fetchUserList()
    },
  },
  mounted() {},
  methods: {
    search() {
      this.currentPage = 1
      this.fetchUserList()
    },
    changePagination(page) {
      this.currentPage = page
      this.fetchUserList()
    },
    getTimeRange(time) {
      this.timeRange = time
    },
    formatKycStatustext(value) {
      if (value === '') return ''
      switch (value) {
        case 0:
          return 'No upload'
        case 2:
          return 'Permanently blocked'
        case 3:
          return 'Auto-pass'
        case 4:
          return 'Verified'
        case 5:
          return 'Re-upload request'
        default:
          return ''
      }
    },
    reset() {
      this.searchKey = null
      this.mode = {
        label: 'User ID',
        value: 1,
      }
      this.userStatus = {
        label: 'All',
        value: 0,
      }
      this.country = { name: 'All', code: '' }
      this.timeRangeDefault = []
      this.timeRange = []
      this.currentPage = 1
    },
  },
  setup() {
    const USERCENTER_APP_STORE_MODULE_NAME = 'userCenter'

    // Register module
    if (!store.hasModule(USERCENTER_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        USERCENTER_APP_STORE_MODULE_NAME,
        userCenterStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USERCENTER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USERCENTER_APP_STORE_MODULE_NAME)
      }
    })
    // 搜索模式 1 - transaction_id, 2 - channel_id(thirdparty_id/partner_id), 3 - uuid, 4 -phone, 5 - document_id, 6 - email, 7 - name， 8 - merchant_id
    const modeOptions = [
      { label: 'User ID', value: 3 },
      { label: 'Phone number', value: 4 },
      { label: 'Email', value: 6 },
      { label: 'Document ID', value: 5 },
      { label: 'Name', value: 7 },
    ]
    // Status
    const statusOption = [
      { label: 'All', value: 0 },
      { label: 'Active', value: 1 },
      { label: 'InActive', value: 2 },
    ]
    const showStatus = { 0: 'All', 1: 'Active', 2: 'InActive' }
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refUserCenterListTable,

      searchKey,
      mode,
      userStatus,
      timeRange,
      timeRangeDefault,

      userCenterList,
      fetchUserList,
      sourceOptions,
      source,
      country,
    } = useDeposit()
    const modeOptionChange = item => {
      mode.value = item
    }
    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      refUserCenterListTable,
      modeOptions,
      statusOption,
      sourceOptions,
      source,
      country,

      searchKey,
      mode,
      userStatus,
      timeRange,
      timeRangeDefault,

      userCenterList,
      showStatus,
      fetchUserList,
      updateDate,
      formatDocumentId,
      formatPhoneNumber,

      modeOptionChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.b-col-top {
  margin-top: 1.67rem;
}
#userCenterListTable th:nth-of-type(8) div {
  width: 200px;
}
#userCenterListTable td:nth-of-type(8) {
  word-break: break-all;
}
</style>
